<template>
    <Modal animation-name="up" class="user-modal" :is-open="modalStore.ResetPassword" @modal-closed="handleCloseModal">
        <template #content>
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" @click="handleCloseModal">
                            <span>×</span>
                        </button>
                        <h4 class="modal-title">{{ blok.Title }}</h4>
                    </div>

                    <div class="modal-body">
                        <template v-if="!isFormStateSuccess">
                            <h3 class="modal-subtitle">{{ blok.SubTitle }}</h3>
                            <form @submit.prevent="submitForm">
                                <FormInput
                                    id="email"
                                    v-model="formData.email"
                                    :placeholder="$t('labels.email2')"
                                    :label="$t('labels.email2')"
                                    name="email"
                                    type="text"
                                    :error-message="errorMessages.email || responseErrorMsg"
                                    @blur="v$.email.$touch"
                                ></FormInput>
                                <AdvancedBtn
                                    v-if="apr"
                                    component-type="button"
                                    component-variant="primary"
                                    button-type="submit"
                                    :full-width="true"
                                >
                                    {{ blok.ButtonText }}
                                </AdvancedBtn>
                                <button v-else type="submit" class="sign-in-button">
                                    {{ blok.ButtonText }}
                                </button>
                            </form>
                        </template>
                        <template v-else>
                            <h3 class="modal-subtitle success-msg">{{ blok.SuccessMessage }}</h3>
                            <button class="sign-in-button close" @click="handleCloseModal">
                                {{ blok.CloseButton }}
                            </button>
                        </template>
                    </div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script setup lang="ts">
import Modal from './Modal.vue';
import AdvancedBtn from '~ui/components/AdvancedBtn.vue';
import { useModalStore } from '~/../_base/store/modal';
import { email, helpers, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import type { ISignUpFormState } from '~ui/types/components/modals/SignUpModal';
import type { IResetPasswordModalConfig, IResetPasswordFormData } from '~ui/types/components/modals/ResetPasswordModal';

const modalStore: any = useModalStore();
const { t } = useI18n();
const props = defineProps<{ blok: IResetPasswordModalConfig; formState: ISignUpFormState; apr?: boolean }>();
const isFormStateSuccess = ref(false);
const responseErrorMsg = ref(null);

const emit = defineEmits<{
    (e: 'submit', formData: IResetPasswordFormData): void;
}>();

const formData = ref<IResetPasswordFormData>({
    email: '',
});
const handleCloseModal = () => {
    isFormStateSuccess.value = false;
    responseErrorMsg.value = null;
    eraseFormData();
    v$.value.$reset();
    modalStore.toggleModal('ResetPassword', false);
};

const eraseFormData = () => {
    formData.value.email = '';
};

const rules = computed(() => {
    return {
        email: {
            required: helpers.withMessage(t('forms.emailRequired3'), required),
            email: helpers.withMessage(t('forms.invalidEmail'), email),
        },
    };
});

const v$ = useVuelidate(rules, formData);

const errorMessages = computed(() => {
    return {
        email: v$.value.email.$errors.map((e) => e.$message)[0],
    };
});

const submitForm = async () => {
    v$.value.$validate();
    if (!v$.value.$error) {
        emit('submit', formData.value);
    }
};

watch(
    () => props.formState,
    (newFormState) => {
        if (newFormState.success) {
            isFormStateSuccess.value = true;
            eraseFormData();
            v$.value.$reset();
        }

        if (newFormState.responseMsg || newFormState.responseMsg?.email) {
            responseErrorMsg.value = newFormState.responseMsg?.email || newFormState.responseMsg;
        }
    },
    { deep: true }
);
</script>
<style scoped lang="postcss">
.modal-subtitle {
    @apply text-[0.8rem] pb-[15px];
}
</style>
